<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.81973 7.60118C3.77913 4.69645 6.1559 3.60205 8.09265 3.60205C9.57183 3.60205 10.9369 4.17805 11.9268 5.23378L12.1036 5.41028L12.3146 5.19982C13.3465 4.17289 14.7115 3.60205 16.1566 3.60205C17.6292 3.60205 19.0004 4.17026 20.0284 5.21887L20.0415 5.23241C21.0317 6.28121 21.5753 7.64784 21.5753 9.07736C21.5753 10.5382 21.0204 11.9095 20.0225 12.9413L20.0186 12.9453L12.8236 20.3013C12.6354 20.4937 12.3775 20.6022 12.1083 20.6021C11.8391 20.6019 11.5813 20.4933 11.3933 20.3007L4.03929 12.7698L4.03756 12.7681C2.72527 11.4175 2.23454 9.39602 2.81854 7.60483L2.81973 7.60118ZM4.71945 8.22654C4.37195 9.29471 4.6652 10.5435 5.47118 11.3735C5.47144 11.3738 5.47169 11.374 5.47194 11.3743L12.1093 18.1713L18.5868 11.5489C19.2196 10.8937 19.5753 10.023 19.5753 9.07736C19.5753 8.16428 19.2312 7.29092 18.5937 6.61229C17.9482 5.95735 17.0968 5.60205 16.1566 5.60205C15.2492 5.60205 14.389 5.95713 13.7254 6.61742C13.7254 6.61743 13.7255 6.61741 13.7254 6.61742L12.9431 7.39778C12.7779 7.5582 12.4991 7.75226 12.0981 7.75226C11.7262 7.75226 11.4319 7.58437 11.2322 7.36631L10.5015 6.63694C10.4929 6.62828 10.4844 6.61947 10.476 6.6105C9.87748 5.96741 9.04407 5.60205 8.09265 5.60205C6.89322 5.60205 5.37107 6.2562 4.71945 8.22654Z" fill="#090909"/>
  </svg>
</template>

<script>
export default {
  name: "FavIcon"
}
</script>

<style scoped>
</style>








